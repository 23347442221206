import React, { useState } from "react"
import PropTypes from "prop-types"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons"

const ReferenceBlock = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleReferences = e => {
    setIsOpen(!isOpen)
    e.preventDefault()
  }
  return (
    <div className="reference-block">
      <div className="row expanded">
        <div className="columns text-center">
          <span className="visually-hidden">Click to expand references.</span>
          <button
            onClick={e => toggleReferences(e)}
            className="reference-block__toggle"
          >
            References &nbsp;
            {!isOpen && <FontAwesomeIcon icon={faAngleDown} />}
            {isOpen && <FontAwesomeIcon icon={faAngleUp} />}
          </button>
        </div>
      </div>
      {isOpen && (
        <div className="row align-center medium-align-left">
          <div className="columns lg_pt-2 lg_pb-4 pb-2 small-12 medium-expand">
            {children}
          </div>
        </div>
      )}
    </div>
  )
}

ReferenceBlock.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ReferenceBlock
